html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-width: 1280px !important;
  background: #f3f2f5;
}

form {
  margin-top: 5px;
}
